<template>
  <div>
    <b-modal
      v-model="showProduct"
      ok-only
      ok-title="Accept"
      centered
      size="lg"
      :hide-footer="true"
      :title="(loading && (!product || !product.slug)) ? 'Chargement...' : product.name"
    >
      <div v-if="product" class="d-flex justify-content-end">
        <a :href="`https://kipli.com/${country.toLowerCase()}/product/${product.slug}`" target="_blank">
          <b-button
            variant="primary"
            class="btn-cart"
          >
            <span>Page produit</span>
          </b-button>
        </a>
      </div>
      <div
        v-if="loading"
        class="d-flex justify-content-center mb-1"
      >
        <b-spinner label="Loading..." />
      </div>
      <div v-else-if="product.type === 'woosb'">
        <div
          v-for="(p, key) of product.woosbProducts"
          :key="key"
        >
          <span>{{ p.name }}</span>
          <create
            :product="p"
            product-type="woosb"
            @add="data => { addWoosb(data);}"
          />
        </div>
        <div>
          <h2>Total du panier:</h2>
          <ul>
            <li
              v-for="(woosb, key) of woosbProducts"
              :key="key"
            >
              <span><strong>{{ woosb.name }}:</strong> {{ woosb.couleur }} / {{ woosb.taille }}</span>
              <feather-icon
                icon="TrashIcon"
                class="ml-5 text-primary"
                style="cursor: pointer;"
                size="20"
                @click="removeWoosb(woosb.name)"
              />
            </li>
          </ul>
        </div>
        <div
          v-if="woosbProducts.length === product.woosbProducts.length"
          class="item-options text-center"
        >
          <b-button
            variant="flat-primary"
            tag="a"
            class="btn-cart mb-1"
            @click="confirmWoosb"
          >
            <feather-icon
              icon="ShoppingCartIcon"
              class="mr-50"
            />
            <span>Créer le bundle</span>
          </b-button>
        </div>
      </div>
      <div v-else-if="product.type === 'carte-cadeau'">
        <b-form @submit.prevent="addCarteCadeau">
          <b-col md="12">
            <p>Entrez le montant que vous souhaitez offrir (ex: 100):</p>
          </b-col>
          <b-col md="6">
            <b-form-input
              v-model="offer"
              type="number"
              min="0"
              placeholder="Entrez le montant de la carte cadeau"
            />
          </b-col>
          <b-col
            cols="9"
            class="mt-2 mb-2"
          >
            <b-button
              type="submit"
              variant="primary"
              class="mr-1"
              :disabled="offer === null || offer < 0"
            >
              Ajouter au panier
            </b-button>
          </b-col>
        </b-form>
      </div>
      <div v-else>
        <create
          :product="product"
          @add="data => { add(data); showProduct = false; $emit('add', data)}"
        />
      </div>
    </b-modal>
    <div class="mt-1 mb-3">
      <b-row>
        <b-col
          cols="12"
        >
          <div class="px-2 py-1 navbar-light header-navbar rounded-lg shadow d-flex align-items-center justify-content-start">
            <feather-icon
              icon="SearchIcon"
              class="mr-50 text-primary"
              size="20"
            />
            <b-input-group class="input-group-merge shadow-none">
              <b-form-input
                placeholder="Rechercher un produit..."
                style="height: auto;"
                class="border-0 shadow-none"
                @input="filter"
              />
            </b-input-group>
          </div>
        </b-col>
      </b-row>
    </div>
    <div v-if="country === 'FR' && (filterQuery === null || filterQuery === '')">
      <h2>Matelas</h2>
      <b-row class="match-height mt-2">
        <b-col
          v-for="pdct in products.filter(p => p.categories.find(c => c.slug === 'matelas'))"
          :key="pdct.id"
          md="4"
          lg="4"
          class=""
        >
          <b-card
            class="ecommerce-card"
            no-body
          >
            <div class="item-img text-center">
              <b-img
                v-if="pdct.images && pdct.images.length"
                :alt="`${pdct.name}`"
                fluid
                class="card-img-top"
                style="height: 180px; object-fit: cover"
                :src="pdct.images[0].src"
              />
            </div>

            <!-- pdct Details -->
            <b-card-body>
              <div class="item-wrapper">
                <div>
                  <h5 class="item-price text-primary text-lighten-2">
                    {{ pdct.type === 'woosb' ? Number(pdct.regular_price) + Number(pdct.price) : pdct.price }} €
                  </h5>
                </div>
              </div>
              <h4 class="item-name">
                {{ pdct.name }}
              </h4>
              <!-- <b-card-text class="item-description">
                {{ product.short_description }}
              </b-card-text> -->
            </b-card-body>

            <!-- Product Actions -->
            <div class="item-options text-center">
              <b-button
                variant="flat-primary"
                tag="a"
                class="btn-cart mb-1"
                @click="get(pdct)"
              >
                <feather-icon
                  icon="ShoppingCartIcon"
                  class="mr-50"
                />
                <span>Ajouter au panier</span>
              </b-button>
            </div>
          </b-card>
        </b-col>
        <b-col
          md="4"
          lg="4"
          class=""
        >
          <b-card
            class="ecommerce-card"
            no-body
          >
            <div class="item-img text-center">
              <b-img
                fluid
                class="card-img-top"
                style="height: 180px; object-fit: cover"
                src="https://kipli.com/fr/wp-content/uploads/2020/12/Cadeaux.jpg"
              />
            </div>

            <!-- pdct Details -->
            <b-card-body>
              <div class="item-wrapper">
                <div>
                  <!-- <h5 class="item-price text-primary text-lighten-2">
                    Montant €
                  </h5> -->
                </div>
              </div>
              <h4 class="item-name">
                Carte cadeau
              </h4>
              <!-- <b-card-text class="item-description">
                {{ product.short_description }}
              </b-card-text> -->
            </b-card-body>

            <!-- Product Actions -->
            <div class="item-options text-center">
              <b-button
                variant="flat-primary"
                tag="a"
                class="btn-cart mb-1"
                @click="carteCadeau"
              >
                <feather-icon
                  icon="ShoppingCartIcon"
                  class="mr-50"
                />
                <span>Ajouter au panier</span>
              </b-button>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <h2>Sommier</h2>
      <b-row class="match-height mt-2">
        <b-col
          v-for="pdct in products.filter(p => p.categories.find(c => c.slug === 'sommier'))"
          :key="pdct.id"
          md="4"
          lg="4"
          class=""
        >
          <b-card
            class="ecommerce-card"
            no-body
          >
            <div class="item-img text-center">
              <b-img
                v-if="pdct.images && pdct.images.length"
                :alt="`${pdct.name}`"
                fluid
                class="card-img-top"
                style="height: 180px; object-fit: cover"
                :src="pdct.images[0].src"
              />
            </div>

            <!-- pdct Details -->
            <b-card-body>
              <div class="item-wrapper">
                <div>
                  <h5 class="item-price text-primary text-lighten-2">
                    {{ pdct.type === 'woosb' ? Number(pdct.regular_price) + Number(pdct.price) : pdct.price }} €
                  </h5>
                </div>
              </div>
              <h4 class="item-name">
                {{ pdct.name }}
              </h4>
              <!-- <b-card-text class="item-description">
                {{ product.short_description }}
              </b-card-text> -->
            </b-card-body>

            <!-- Product Actions -->
            <div class="item-options text-center">
              <b-button
                variant="flat-primary"
                tag="a"
                class="btn-cart mb-1"
                @click="get(pdct)"
              >
                <feather-icon
                  icon="ShoppingCartIcon"
                  class="mr-50"
                />
                <span>Ajouter au panier</span>
              </b-button>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <h2>Mobilier</h2>
      <b-row class="match-height mt-2">
        <b-col
          v-for="pdct in products.filter(p => p.categories.find(c => c.slug === 'mobilier'))"
          :key="pdct.id"
          md="4"
          lg="4"
          class=""
        >
          <b-card
            class="ecommerce-card"
            no-body
          >
            <div class="item-img text-center">
              <b-img
                v-if="pdct.images && pdct.images.length"
                :alt="`${pdct.name}`"
                fluid
                class="card-img-top"
                style="height: 180px; object-fit: cover"
                :src="pdct.images[0].src"
              />
            </div>

            <!-- pdct Details -->
            <b-card-body>
              <div class="item-wrapper">
                <div>
                  <h5 class="item-price text-primary text-lighten-2">
                    {{ pdct.type === 'woosb' ? Number(pdct.regular_price) + Number(pdct.price) : pdct.price }} €
                  </h5>
                </div>
              </div>
              <h4 class="item-name">
                {{ pdct.name }}
              </h4>
              <!-- <b-card-text class="item-description">
                {{ product.short_description }}
              </b-card-text> -->
            </b-card-body>

            <!-- Product Actions -->
            <div class="item-options text-center">
              <b-button
                variant="flat-primary"
                tag="a"
                class="btn-cart mb-1"
                @click="get(pdct)"
              >
                <feather-icon
                  icon="ShoppingCartIcon"
                  class="mr-50"
                />
                <span>Ajouter au panier</span>
              </b-button>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <h2>Linge de lit</h2>
      <b-row class="match-height mt-2">
        <b-col
          v-for="pdct in products.filter(p => p.categories.find(c => ['le-linge-de-lit', 'linge-de-lit'].includes(c.slug)))"
          :key="pdct.id"
          md="4"
          lg="4"
          class=""
        >
          <b-card
            class="ecommerce-card"
            no-body
          >
            <div class="item-img text-center">
              <b-img
                v-if="pdct.images && pdct.images.length"
                :alt="`${pdct.name}`"
                fluid
                class="card-img-top"
                style="height: 180px; object-fit: cover"
                :src="pdct.images[0].src"
              />
            </div>

            <!-- pdct Details -->
            <b-card-body>
              <div class="item-wrapper">
                <div>
                  <h5 class="item-price text-primary text-lighten-2">
                    {{ pdct.type === 'woosb' ? Number(pdct.regular_price) + Number(pdct.price) : pdct.price }} €
                  </h5>
                </div>
              </div>
              <h4 class="item-name">
                {{ pdct.name ? pdct.name : pdct.name }}
              </h4>
              <!-- <b-card-text class="item-description">
                {{ product.short_description }}
              </b-card-text> -->
            </b-card-body>

            <!-- Product Actions -->
            <div class="item-options text-center">
              <b-button
                variant="flat-primary"
                tag="a"
                class="btn-cart mb-1"
                @click="get(pdct)"
              >
                <feather-icon
                  icon="ShoppingCartIcon"
                  class="mr-50"
                />
                <span>Ajouter au panier</span>
              </b-button>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <h2>Oreiller</h2>
      <b-row class="match-height mt-2">
        <b-col
          v-for="pdct in products.filter(p => p.categories.find(c => c.slug === 'oreiller'))"
          :key="pdct.id"
          md="4"
          lg="4"
          class=""
        >
          <b-card
            class="ecommerce-card"
            no-body
          >
            <div class="item-img text-center">
              <b-img
                v-if="pdct.images && pdct.images.length"
                :alt="`${pdct.name}`"
                fluid
                class="card-img-top"
                style="height: 180px; object-fit: cover"
                :src="pdct.images[0].src"
              />
            </div>

            <!-- pdct Details -->
            <b-card-body>
              <div class="item-wrapper">
                <div>
                  <h5 class="item-price text-primary text-lighten-2">
                    {{ pdct.type === 'woosb' ? Number(pdct.regular_price) + Number(pdct.price) : pdct.price }} €
                  </h5>
                </div>
              </div>
              <h4 class="item-name">
                {{ pdct.name }}
              </h4>
              <!-- <b-card-text class="item-description">
                {{ product.short_description }}
              </b-card-text> -->
            </b-card-body>

            <!-- Product Actions -->
            <div class="item-options text-center">
              <b-button
                variant="flat-primary"
                tag="a"
                class="btn-cart mb-1"
                @click="get(pdct)"
              >
                <feather-icon
                  icon="ShoppingCartIcon"
                  class="mr-50"
                />
                <span>Ajouter au panier</span>
              </b-button>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <h2>Autres produits</h2>
      <b-row class="match-height mt-2">
        <b-col
          v-for="pdct in products.filter(p => p.categories.filter(c => ['le-linge-de-lit', 'linge-de-lit', 'mobilier', 'sommier', 'matelas', 'oreiller'].includes(c.slug)).length === 0)"
          :key="pdct.id"
          md="4"
          lg="4"
          class=""
        >
          <b-card
            class="ecommerce-card"
            no-body
          >
            <div class="item-img text-center">
              <b-img
                v-if="pdct.images && pdct.images.length"
                :alt="`${pdct.name}`"
                fluid
                class="card-img-top"
                style="height: 180px; object-fit: cover"
                :src="pdct.images[0].src"
              />
            </div>

            <!-- pdct Details -->
            <b-card-body>
              <div class="item-wrapper">
                <div>
                  <h5 class="item-price text-primary text-lighten-2">
                    {{ pdct.type === 'woosb' ? Number(pdct.regular_price) + Number(pdct.price) : pdct.price }} €
                  </h5>
                </div>
              </div>
              <h4 class="item-name">
                {{ pdct.name }}
              </h4>
              <!-- <b-card-text class="item-description">
                {{ product.short_description }}
              </b-card-text> -->
            </b-card-body>

            <!-- Product Actions -->
            <div class="item-options text-center">
              <b-button
                variant="flat-primary"
                tag="a"
                class="btn-cart mb-1"
                @click="get(pdct)"
              >
                <feather-icon
                  icon="ShoppingCartIcon"
                  class="mr-50"
                />
                <span>Ajouter au panier</span>
              </b-button>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <b-row v-else>
      <b-col
        v-for="pdct in products"
        :key="pdct.id"
        md="4"
        lg="4"
        class=""
      >
        <b-card
          class="ecommerce-card"
          no-body
        >
          <div class="item-img text-center">
            <b-img
              v-if="pdct.images && pdct.images.length"
              :alt="`${pdct.name}`"
              fluid
              class="card-img-top"
              style="height: 180px; object-fit: cover"
              :src="pdct.images[0].src"
            />
          </div>

          <!-- pdct Details -->
          <b-card-body>
            <div class="item-wrapper">
              <div>
                <h5 class="item-price text-primary text-lighten-2">
                  {{ pdct.type === 'woosb' ? Number(pdct.regular_price) + Number(pdct.price) : pdct.price }} €
                </h5>
              </div>
            </div>
            <h4 class="item-name">
              {{ pdct.name }}
            </h4>
            <!-- <b-card-text class="item-description">
              {{ product.short_description }}
            </b-card-text> -->
          </b-card-body>

          <!-- Product Actions -->
          <div class="item-options text-center">
            <b-button
              variant="flat-primary"
              tag="a"
              class="btn-cart mb-1"
              @click="get(pdct)"
            >
              <feather-icon
                icon="ShoppingCartIcon"
                class="mr-50"
              />
              <span>Ajouter au panier</span>
            </b-button>
          </div>
        </b-card>
      </b-col>
      <b-col
        md="4"
        lg="4"
        class=""
      >
        <b-card
          class="ecommerce-card"
          no-body
        >
          <div class="item-img text-center">
            <b-img
              fluid
              class="card-img-top"
              style="height: 180px; object-fit: cover"
              src="https://kipli.com/fr/wp-content/uploads/2020/12/Cadeaux.jpg"
            />
          </div>

          <!-- pdct Details -->
          <b-card-body>
            <div class="item-wrapper">
              <div>
                <h5 class="item-price text-primary text-lighten-2">
                  Montant €
                </h5>
              </div>
            </div>
            <h4 class="item-name">
              Carte cadeau
            </h4>
            <!-- <b-card-text class="item-description">
              {{ product.short_description }}
            </b-card-text> -->
          </b-card-body>

          <!-- Product Actions -->
          <div class="item-options text-center">
            <b-button
              variant="flat-primary"
              tag="a"
              class="btn-cart mb-1"
              @click="carteCadeau()"
            >
              <feather-icon
                icon="ShoppingCartIcon"
                class="mr-50"
              />
              <span>Ajouter au panier</span>
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BModal, BRow, BCol, BCardBody, BImg, BButton, BInputGroup, BForm, BFormInput, BSpinner,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
import Create from '@/components/encaissement/forms/Create.vue'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  props: {
    country: {
      type: String,
      default: 'FR',
    },
    reset: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Create,
    BCard,
    BRow,
    BCol,
    BModal,
    BCardBody,
    BImg,
    BButton,
    BForm,
    BFormInput,
    BInputGroup,
    BSpinner,
  },
  data() {
    return {
      baseProducts: [],
      products: [],
      product: {},
      showProduct: false,
      loading: false,
      woosbProducts: [],
      filterQuery: '',
      offer: 0,
    }
  },
  watch: {
    country: {
      handler: 'getProducts',
    },
  },
  async mounted() {
    await this.getProducts()
  },
  methods: {
    ...mapActions('cart', ['add', 'reset']),
    async getProducts() {
      try {
        if (this.reset) {
          // this.reset()
        }
        const { products } = await this.$http.get(`/products?country=${this.country}`)
        if (products) {
          this.products = products
          this.baseProducts = products
        }
      } finally {
        this.loading = false
      }
    },
    async get({ slug }) {
      try {
        this.loading = true
        this.showProduct = true
        const { product } = await this.$http.get(`/products/${slug}?country=${this.country}`)
        this.product = product
      } finally {
        this.loading = false
      }
    },
    carteCadeau() {
      try {
        this.showProduct = true
        this.product = { type: 'carte-cadeau', name: 'Carte cadeau' }
      } finally {
        this.loading = false
      }
    },
    addCarteCadeau() {
      try {
        this.add({
          type: 'carte-cadeau',
          price: this.offer,
          priceCadeau: this.offer,
          title: 'Carte cadeau',
          images: [{ src: 'https://kipli.com/fr/wp-content/uploads/2020/12/Cadeaux.jpg' }],
          variationId: this.offer,
          quantity: 1,
        })
        this.showProduct = false
      } finally {
        this.loading = false
      }
    },
    removeWoosb(name) {
      this.woosbProducts = this.woosbProducts.filter(w => w.name !== name)
    },
    addWoosb(data) {
      if (this.woosbProducts.length === this.product?.woosbProducts?.length) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Vous ne pouvez plus ajouter ce produit.',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
      } else if (this.woosbProducts.find(w => w.name === data.name)) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Vous avez déjà ajouté ce produit.',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
      } else {
        this.woosbProducts.push({
          ...data,
          color: data.couleur,
          price: Number(data.price),
          meta_data: [{
            key: '_woosb_parent_id',
            value: `${this.product.id}`,
          },
          {
            key: '_isTakeAway',
            value: data.isTakeAway || false,
          }],
        })
      }
    },
    confirmWoosb() {
      // if (this.woosbProducts.filter(w => w.couleur === this.woosbProducts[0].couleur).length !== this.product.woosbProducts.length) {
      //   this.$toast({
      //     component: ToastificationContentVue,
      //     props: {
      //       title: 'Vos trois produits doivent avoir la même couleur.',
      //       icon: 'EditIcon',
      //       variant: 'warning',
      //     },
      //   })
      // } else {
      let price = this.woosbProducts?.map(w => Number(w?.price))?.reduce((a, b) => a + b, 0)
      console.log('price', price, this.product)
      if ([103376, 2036].includes(this.product.id)) {
        price -= 4
      }
      if (this.product.id === 132148) {
        price += 49
      }
      if ([103354, 1163].includes(this.product.id)) {
        price -= 4
      }
      if (this.product.id === 108259) {
        price -= 8
      }
      if ([135076, 134413, 142084].includes(this.product.id)) {
        price += 79
      }
      if ([135076].includes(this.product.id)) {
        price -= 4
      }
      if ([118054, 135075, 135074, 133727].includes(this.product.id)) {
        price -= Number((price / 10).toFixed(0))
      }
      if (this.product.id === 134421) {
        price -= 148
      }
      if (this.product.id === 142084) {
        price -= 79
      }
      if ([134413, 135076].includes(this.product.id)) {
        price -= 30
      }
      if (this.product.id === 109483) {
        price += 490 * (this.product.woosb[0].quantity - 1)
        price -= 125 * (this.product.woosb[0].quantity - 1)
      }
      const product = {
        ...this.product,
        price,
        variationId: this.woosbProducts.map(w => w.variationId).join('/'),
        quantity: 1,
        woosb: this.woosbProducts,
        isTakeAway: !!this.woosbProducts.find(w => w.isTakeAway === true),
      }
      this.add(product)
      this.$emit('add', product)
      this.woosbProducts = []
      // eslint-disable-next-line no-restricted-syntax
      // for (const woosb of this.woosbProducts) {
      //   this.add({ ...woosb, notToUse: true })
      //   this.$emit('add', product)
      // }
      this.showProduct = false
      // }
    },
    filter(e) {
      this.filterQuery = e
      if (!e || e === '') {
        this.products = this.baseProducts
      } else {
        const value = e
        this.products = this.baseProducts.filter(product => product.name.toLowerCase().includes(value.toLowerCase()) || product.variations.find(v => v.sku.toLowerCase() === value.toLowerCase()))
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/base/pages/app-ecommerce.scss";

.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
</style>
